import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HealthService {

  constructor(private http: HttpClient) { }

  public getApiStatus() {
    return this.http.get('/api/health/status');
  }

  public getApiVersion() {
    return this.http.get('/api/health/version');
  }
}
