import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { RecipesService } from "src/app/services/recipes.service";
import { Recipe } from "src/app/class/recipe.class";
import { Dish } from "src/app/enum/dishType.enum";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-recipes",
  templateUrl: "./recipes.component.html",
  styleUrls: ["./recipes.component.scss"],
})
export class RecipesComponent implements OnInit {
  @ViewChild("top", { static: false }) topDiv: ElementRef;
  public dishTypes = Dish;
  public activeDishType = Dish.ALL;
  public recipes: Recipe[] = [];
  public paginationConfig = {
    itemsPerPage: 12,
    currentPage: 1,
    totalItems: 0,
  };

  constructor(
    private recipesService: RecipesService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const filters = {} as { page: number; dishType?: Dish };
      if (params.type) {
        filters["dishType"] = parseInt(params.type, 10);
      }
      if (params.page) {
        filters["page"] = parseInt(params.page, 10);
      }
      this.getData(filters);
    });
  }

  public dishKeys(): Array<string> {
    const keys = Object.keys(this.dishTypes);
    return keys.slice(keys.length / 2);
  }

  public selectDishType(dishType: Dish) {
    this.getData({ page: 1, dishType });
  }

  /**
   * Query data with filters
   */
  public getData(filters: { page: number; dishType?: Dish }) {
    const dishType =
      filters.dishType !== undefined ? filters.dishType : this.activeDishType;

    this.recipesService.findWithFilters({ dishType }).subscribe((res) => {
      this.recipes = res;
      this.activeDishType = dishType;
      this.paginationConfig.currentPage = filters.page;
      this.paginationConfig.totalItems = this.recipes.length;
      this.savePagination();
    });
  }

  public onPageChange(newPage: number) {
    this.topDiv.nativeElement.scrollIntoView({
      block: "start",
    });
    this.paginationConfig.currentPage = newPage;
    this.savePagination();
  }

  savePagination() {
    // changes the route without moving from the current view or
    // triggering a navigation event,
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        type: this.activeDishType,
        page: this.paginationConfig.currentPage,
      },
      // preserve the existing query params in the route
      queryParamsHandling: "merge",
    });

    // Save query params for recipe link back
    this.recipesService.paginationQueryParams = this.route.snapshot.queryParams;
  }
}
