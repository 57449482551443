import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Advice } from "../class/advice.class";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AdvicesService {
  constructor(private http: HttpClient) {}

  public findAll(): Observable<Advice[]> {
    return this.http.get<Advice[]>(`/api/advices`);
  }

  public findOne(id: number): Observable<Advice> {
    return this.http.get<Advice>(`/api/advices/${id}`);
  }

  public findPrevious(id: number): Observable<Advice> {
    return this.http.get<Advice>(`/api/advices/${id}/previous`);
  }

  public findNext(id: number): Observable<Advice> {
    return this.http.get<Advice>(`/api/advices/${id}/next`);
  }
}
