import { Component, OnInit } from "@angular/core";
import { QuizQuestion } from "src/app/class/quizQuestion.class";
import { QuizQuestionsService } from "src/app/services/quizQuestions.service";

@Component({
  selector: "app-quiz",
  templateUrl: "./quiz.component.html",
  styleUrls: ["./quiz.component.scss"],
})
export class QuizComponent implements OnInit {
  public quizQuestions: QuizQuestion[];
  public currentQuestionIndex = 0;
  public currentAnswers: number[] = [];
  public answersAreCorrect = false;
  public correctAnswers = 0;
  public showInfoModal = false;
  public showResult = false;

  constructor(private quizQuestionsService: QuizQuestionsService) {}

  ngOnInit() {
    this.quizQuestionsService.findAll().subscribe((res) => {
      this.quizQuestions = res;
    });
  }

  selectProposition(propositionIndex: number) {
    const foundCurrentAnswerIndex = this.currentAnswers.findIndex(
      (answer) => answer === propositionIndex
    );

    if (foundCurrentAnswerIndex > -1) {
      this.currentAnswers.splice(foundCurrentAnswerIndex, 1);
    } else {
      this.currentAnswers.push(propositionIndex);
    }
  }

  submitAnswer() {
    if (
      JSON.stringify(this.currentAnswers.sort()) ===
      JSON.stringify(this.quizQuestions[this.currentQuestionIndex].answers)
    ) {
      this.answersAreCorrect = true;
      this.correctAnswers++;
    }

    this.showInfoModal = true;
  }

  closeInfoModal() {
    this.showInfoModal = false;

    setTimeout(() => {
      this.currentAnswers = [];
      this.answersAreCorrect = false;
      this.currentQuestionIndex++;

      if (this.currentQuestionIndex === this.quizQuestions.length) {
        this.showResult = true;
      }
    }, 200);
  }
}
