import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from "@angular/router";
import { Advice } from "src/app/class/advice.class";
import { AdvicesService } from "src/app/services/advices.service";
import { StorageService } from "src/app/services/storage.service";

@Component({
  selector: "app-advice",
  templateUrl: "./advice.component.html",
  styleUrls: ["./advice.component.scss"],
})
export class AdviceComponent implements OnInit, OnDestroy {
  public advice: Advice;
  public previousAdvice: Advice;
  public nextAdvice: Advice;
  private shouldReuseRouteBackup: (
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ) => boolean;

  constructor(
    private storageService: StorageService,
    private route: ActivatedRoute,
    private router: Router,
    private advicesService: AdvicesService
  ) {}

  ngOnInit() {
    this.advice = this.route.snapshot.data["advice"];

    this.advicesService
      .findPrevious(this.advice.id)
      .subscribe((res) => (this.previousAdvice = res));
    this.advicesService
      .findNext(this.advice.id)
      .subscribe((res) => (this.nextAdvice = res));

    // Allows to navigate from this component to this component with different parameters
    this.shouldReuseRouteBackup = this.router.routeReuseStrategy.shouldReuseRoute;
    this.router.routeReuseStrategy.shouldReuseRoute = (
      future: ActivatedRouteSnapshot,
      curr: ActivatedRouteSnapshot
    ) => false;
  }

  ngOnDestroy() {
    this.router.routeReuseStrategy.shouldReuseRoute = this.shouldReuseRouteBackup;
  }

  public isInTipPlan(action: string) {
    return (
      this.storageService.getActionPlan().tips[
        this.advice.adviceCategory.name
      ] &&
      this.storageService
        .getActionPlan()
        .tips[this.advice.adviceCategory.name].includes(action)
    );
  }

  public addActionToTipPlan(action: string) {
    this.storageService.addToActionPlan(
      "tips",
      this.advice.adviceCategory.name,
      action
    );
  }

  public removeActionFromTipPlan(action: string) {
    this.storageService.removeFromActionPlan(
      "tips",
      this.advice.adviceCategory.name,
      action
    );
  }

  public isInResourcePlan(resource: string, action: string) {
    return (
      this.storageService.getActionPlan().resources[resource] &&
      this.storageService.getActionPlan().resources[resource].includes(action)
    );
  }

  public addActionToResourcePlan(resource: string, action: string) {
    this.storageService.addToActionPlan("resources", resource, action);
  }

  public removeActionFromResourcePlan(resource: string, action: string) {
    this.storageService.removeFromActionPlan("resources", resource, action);
  }

  public goToAdvice(id: number) {
    this.router.navigate(["advices", id]);
  }
}
