import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Params,
  Router,
} from "@angular/router";
import { Recipe } from "src/app/class/recipe.class";
import { RecipesService } from "src/app/services/recipes.service";

@Component({
  selector: "app-recipe",
  templateUrl: "./recipe.component.html",
  styleUrls: ["./recipe.component.scss"],
})
export class RecipeComponent implements OnInit, OnDestroy {
  public recipe: Recipe;
  public previousRecipe: Recipe;
  public nextRecipe: Recipe;
  private shouldReuseRouteBackup: (
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ) => boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public recipesService: RecipesService
  ) {}

  ngOnInit() {
    /* Get recipe from route resolver */
    this.route.queryParams.subscribe((params: Params) => {
      this.recipe = this.route.snapshot.data["recipe"] || new Recipe();
    });

    this.recipesService
      .findPrevious(
        this.recipe.id,
        this.recipesService.paginationQueryParams["type"]
      )
      .subscribe((res) => (this.previousRecipe = res));

    this.recipesService
      .findNext(
        this.recipe.id,
        this.recipesService.paginationQueryParams["type"]
      )
      .subscribe((res) => (this.nextRecipe = res));

    // Allows to navigate from this component to this component with different parameters
    this.shouldReuseRouteBackup = this.router.routeReuseStrategy.shouldReuseRoute;
    this.router.routeReuseStrategy.shouldReuseRoute = (
      future: ActivatedRouteSnapshot,
      curr: ActivatedRouteSnapshot
    ) => false;
  }

  ngOnDestroy(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = this.shouldReuseRouteBackup;
  }

  public goToRecipe(id: number) {
    this.router.navigate(["recipes", id]);
  }
}
