import { Component, Input, OnInit } from "@angular/core";
import { AdviceCategory } from "src/app/class/adviceCategory.class";
import { AdviceCategoriesService } from "src/app/services/adviceCategories.service";

@Component({
  selector: "app-quiz-result",
  templateUrl: "./result.component.html",
  styleUrls: ["./result.component.scss"],
})
export class QuizResultComponent implements OnInit {
  @Input() public correctAnswers: number;
  @Input() public questionsCount: number;

  public adviceCategories: AdviceCategory[];

  constructor(private adviceCategoriesService: AdviceCategoriesService) {}

  ngOnInit() {
    this.adviceCategoriesService.findAll().subscribe((res) => {
      this.adviceCategories = res;
    });
  }
}
