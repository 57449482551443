import { Component, OnInit } from "@angular/core";
import { HealthService } from "src/app/services/health.service";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-health",
  templateUrl: "./health.component.html",
})
export class HealthComponent implements OnInit {
  public apiStatus: any;
  public apiVersion: any;
  public version: string = environment.version;

  constructor(private healthService: HealthService) {}

  ngOnInit() {
    this.healthService
      .getApiStatus()
      .subscribe((res) => (this.apiStatus = res));
    this.healthService
      .getApiVersion()
      .subscribe((res) => (this.apiVersion = res));
  }
}
