import { Component, OnInit } from "@angular/core";
import { PdfService } from "src/app/services/pdf.service";
import { ActionPlan, StorageService } from "src/app/services/storage.service";

@Component({
  selector: "app-action-plan",
  templateUrl: "./action-plan.component.html",
  styleUrls: ["./action-plan.component.scss"],
})
export class ActionPlanComponent implements OnInit {
  public actionPlan = { tips: {}, resources: {} } as ActionPlan;
  public Object = Object;

  constructor(
    private storageService: StorageService,
    private pdfService: PdfService
  ) {}

  ngOnInit() {
    this.updateActionPlan();
    this.storageService.onActionPlanUpdate.subscribe(() => {
      this.updateActionPlan();
    });
  }

  private updateActionPlan() {
    this.actionPlan = this.storageService.getActionPlan();
  }

  public removeActionFromTipPlan(adviceCategory: string, action: string) {
    this.storageService.removeFromActionPlan("tips", adviceCategory, action);
    this.actionPlan = this.storageService.getActionPlan();
  }

  public removeActionFromResourcePlan(resource: string, action: string) {
    this.storageService.removeFromActionPlan("resources", resource, action);
    this.actionPlan = this.storageService.getActionPlan();
  }

  public getActionsCount() {
    return this.storageService.getActionsCount();
  }

  public getResourcesActionsCount() {
    return this.storageService.getSectionActionsCount("resources");
  }

  public isBasketVisible(): boolean {
    return this.storageService.getShowBasket();
  }

  public hideBasket() {
    this.storageService.setShowBasket(false);
  }

  public generateActionPlanPdf() {
    this.pdfService.generateActionPlanPdf(this.actionPlan).subscribe((res) => {
      const file = new Blob([res], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(file);
      link.download = "plan_d_action.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }
}
