import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Recipe } from "../class/recipe.class";
import { Observable } from "rxjs";
import { Dish } from "../enum/dishType.enum";

@Injectable({
  providedIn: "root",
})
export class RecipesService {
  public paginationQueryParams = {};

  constructor(private http: HttpClient) {}

  public findAll(): Observable<Recipe[]> {
    return this.http.get<Recipe[]>(`/api/recipes`);
  }

  public save(recipe: Recipe): Observable<Recipe> {
    return this.http.post<Recipe>(`/api/recipes`, recipe);
  }

  public findOne(id: number): Observable<Recipe> {
    return this.http.get<Recipe>(`/api/recipes/${id}`);
  }

  public findWithFilters(filters: object): Observable<Recipe[]> {
    return this.http.post<Recipe[]>(`/api/recipes/filter`, filters);
  }

  public remove(id: number): Observable<any> {
    return this.http.delete<any>(`/api/recipes/${id}`);
  }

  public findPrevious(id: number, type: Dish): Observable<Recipe> {
    return this.http.post<Recipe>(`/api/recipes/${id}/previous`, { type });
  }

  public findNext(id: number, type: Dish): Observable<Recipe> {
    return this.http.post<Recipe>(`/api/recipes/${id}/next`, { type });
  }
}
