import { Component, ElementRef, OnInit } from "@angular/core";
import { trigger, style, animate, transition } from "@angular/animations";
import { StorageService } from "src/app/services/storage.service";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-header",
  animations: [
    trigger("openAnimation", [
      transition(":enter", [
        style({ transform: "translateX(-100%)" }),
        animate("200ms", style({ transform: "translateX(0)" })),
      ]),
    ]),
    trigger("closeAnimation", [
      transition(":leave", [
        style({ opacity: 0.6 }),
        animate("200ms", style({ opacity: 0 })),
      ]),
    ]),
    trigger("fadeAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("200ms", style({ opacity: 0.6 })),
      ]),
      transition(":leave", [
        style({ opacity: 0.1, background: "rgba(44, 44, 44, 0.6)" }),
        animate(
          "500ms",
          style({ opacity: 0, background: "rgba(44, 44, 44, 0)" })
        ),
      ]),
    ]),
  ],
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  public menuDisplayed = false;
  public nbActions = 0;

  constructor(
    private storageService: StorageService,
    private el: ElementRef,
    private appComponent: AppComponent
  ) {}

  ngOnInit() {
    this.getActionsCount();
    this.storageService.onActionPlanUpdate.subscribe(() => {
      this.appComponent.headerClass = "middle";
      this.triggerCountBadgeAnimation();
      this.getActionsCount();
    });
  }

  public toggleMenu() {
    this.menuDisplayed = !this.menuDisplayed;
    document.body.style.overflow = this.menuDisplayed ? "hidden" : "initial";
  }

  public showBasket() {
    this.storageService.setShowBasket(true);
  }

  private getActionsCount() {
    this.nbActions = this.storageService.getActionsCount();
  }

  private triggerCountBadgeAnimation() {
    const countBadge = this.el.nativeElement.querySelector(".count-badge");
    countBadge.classList.add("beat");
    setTimeout(() => {
      countBadge.classList.remove("beat");
    }, 700);
  }
}
