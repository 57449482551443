import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AdviceCategory } from "../class/adviceCategory.class";
import { Observable } from "rxjs";
import { QuizQuestion } from "../class/quizQuestion.class";

@Injectable({
  providedIn: "root",
})
export class QuizQuestionsService {
  constructor(private http: HttpClient) {}

  public findAll(): Observable<QuizQuestion[]> {
    return this.http.get<QuizQuestion[]>(`/api/quizQuestions`);
  }

  public findOne(id: number): Observable<QuizQuestion> {
    return this.http.get<QuizQuestion>(`/api/adviceCategories/${id}`);
  }
}
