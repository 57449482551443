import { Component, OnInit, AfterViewInit, HostListener } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Recipe } from "src/app/class/recipe.class";
import { RecipesService } from "src/app/services/recipes.service";

@Component({
  selector: "app-print-recipe",
  templateUrl: "./print-recipe.component.html",
  styleUrls: ["./print-recipe.component.scss"],
})
export class PrintableRecipeComponent implements OnInit, AfterViewInit {
  @HostListener("window:afterprint")
  onafterprint() {
    this.router.navigate(["recipes", this.recipe.id]);
  }

  public recipe: Recipe;
  public linkBack: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public recipesService: RecipesService
  ) {}

  ngOnInit() {
    /* Get recipe from route resolver */
    this.route.queryParams.subscribe((params: Params) => {
      this.recipe = this.route.snapshot.data["recipe"] || new Recipe();
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      window.print();
    });
  }
}
