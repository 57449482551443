import { Component } from "@angular/core";
import { Event, Router, RouterEvent } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent {
  public showQuiz = false;

  constructor(private router: Router) {
    this.router.events
      .pipe(filter((e: Event): e is RouterEvent => e instanceof RouterEvent))
      .subscribe(() => {
        this.showQuiz = false;
      });
  }

  launchQuiz() {
    this.showQuiz = true;
  }
}
