import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

export interface ActionPlan {
  tips: { [key: string]: string[] };
  resources: { [key: string]: string[] };
}

@Injectable({
  providedIn: "root",
})
export class StorageService {
  public onActionPlanUpdate = new Subject<number>();
  private static readonly ACCESS_TOKEN = "access_token";
  private static readonly ACTION_PLAN = "action_plan";
  private static readonly REDIRECT_URI = "redirect_uri";
  private static readonly SHOW_BASKET = "show_basket";
  private static readonly LAST_VISIT = "last_visit";

  public init() {
    const lastVisit = localStorage.getItem(StorageService.LAST_VISIT);

    const currentDate = new Date();

    if (
      !lastVisit ||
      currentDate.getTime() - new Date(lastVisit).getTime() >
        24 * 60 * 60 * 1000
    ) {
      localStorage.clear();
    }

    localStorage.setItem(StorageService.LAST_VISIT, currentDate.toISOString());
  }

  public getActionPlan(): ActionPlan {
    return (
      JSON.parse(localStorage.getItem(StorageService.ACTION_PLAN)) || {
        tips: {},
        resources: {},
      }
    );
  }

  public getActionsCount() {
    return (
      this.getSectionActionsCount("tips") +
      this.getSectionActionsCount("resources")
    );
  }

  public getSectionActionsCount(section: "tips" | "resources") {
    let actionsCount = 0;
    const actionPlan = this.getActionPlan();

    if (actionPlan[section]) {
      for (let category of Object.keys(actionPlan[section])) {
        actionsCount += actionPlan[section][category].length;
      }
    }

    return actionsCount;
  }

  public addToActionPlan(
    section: "tips" | "resources",
    category: string,
    action: string
  ) {
    let actionPlan = this.getActionPlan();

    // Check if already exist in array
    if (
      actionPlan[section] &&
      actionPlan[section][category] &&
      !actionPlan[section][category].includes(action)
    ) {
      actionPlan[section][category].push(action);
    } else if (!actionPlan[section]) {
      actionPlan[section] = {};
      actionPlan[section][category] = [action];
    } else if (!actionPlan[section][category]) {
      actionPlan[section][category] = [action];
    }

    localStorage.setItem(
      StorageService.ACTION_PLAN,
      JSON.stringify(actionPlan)
    );
    this.onActionPlanUpdate.next(1);
  }

  public removeFromActionPlan(
    section: "tips" | "resources",
    category: string,
    action: string
  ) {
    let actionPlan = this.getActionPlan();
    if (actionPlan[section] && actionPlan[section][category]) {
      actionPlan[section][category] = actionPlan[section][category].filter(
        (element) => element !== action
      );
      localStorage.setItem(
        StorageService.ACTION_PLAN,
        JSON.stringify(actionPlan)
      );
      this.onActionPlanUpdate.next(1);
    }
  }

  public getShowBasket(): boolean {
    return localStorage.getItem(StorageService.SHOW_BASKET) === "true"
      ? true
      : false;
  }

  public setShowBasket(showBasket: boolean) {
    localStorage.setItem(StorageService.SHOW_BASKET, showBasket.toString());
  }

  public getAccessToken(): string {
    return localStorage.getItem(StorageService.ACCESS_TOKEN);
  }

  public setAccessToken(accessToken: string) {
    localStorage.setItem(StorageService.ACCESS_TOKEN, accessToken);
  }

  public getRedirectUri(): string {
    const uri = localStorage.getItem(StorageService.REDIRECT_URI);
    localStorage.removeItem(StorageService.REDIRECT_URI);
    return uri;
  }

  public setRedirectUri(redirectUri: string) {
    localStorage.setItem(StorageService.REDIRECT_URI, redirectUri);
  }
}
