import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { ModalService } from 'src/app/services/modal.service';
import { StorageService } from 'src/app/services/storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  public modalID = 'login-modal';
  public authForm: FormGroup;

  constructor(
    private loginService: LoginService,
    private modalService: ModalService,
    private storageService: StorageService,
    private router: Router) { }

  ngOnInit() {
    this.authForm = new FormGroup({
      username: new FormControl(),
      password: new FormControl(),
    });
  }

  public login() {
    this.loginService.login({ username: this.authForm.value.username, password: this.authForm.value.password })
      .subscribe((res) => {
        this.storageService.setAccessToken(res['access_token']);
        this.modalService.close(this.modalID);
        this.router.navigate([this.storageService.getRedirectUri()]);
      }, (err) => {
        console.log(err);
      });
  }

  public closeModal() {
    this.modalService.close(this.modalID);
  }
}
