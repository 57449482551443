import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ActionPlan } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class PdfService {
  constructor(private http: HttpClient) {}

  public generateActionPlanPdf(
    actionPlan: ActionPlan
  ): Observable<ArrayBuffer> {
    return this.http.post<ArrayBuffer>(`/api/pdf/action-plan/`, actionPlan, {
      responseType: "blob" as "json",
    });
  }
}
