import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatomoModule } from "ngx-matomo";
import {
  SwiperModule,
  SWIPER_CONFIG,
  SwiperConfigInterface,
} from "ngx-swiper-wrapper";
import { NgxPaginationModule } from "ngx-pagination";
import { CookieLawModule } from "angular2-cookie-law";
import { MatTabsModule } from "@angular/material/tabs";

/* Components */
import { AppComponent } from "./app.component";
import { HealthComponent } from "./components/health/health.component";
import { HomeComponent } from "./components/home/home.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { AdvicesComponent } from "./components/advices/advices.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { NavbarComponent } from "./components/header/navbar/navbar.component";
import { RecipesComponent } from "./components/recipes/recipes.component";
import { RecipeComponent } from "./components/recipes/recipe/recipe.component";
import { PrintableRecipeComponent } from "./components/recipes/print-recipe/print-recipe.component";
import { RecipePreviewComponent } from "./components/recipes/recipe-preview/recipe-preview.component";
// import { RecipeFormComponent } from "./components/recipes/recipe-form/recipe-form.component";
import { AdviceComponent } from "./components/advices/advice/advice.component";
import { LoginComponent } from "./components/login/login.component";
import { QuizComponent } from "./components/quiz/quiz.component";
import { QuizResultComponent } from "./components/quiz/result/result.component";

/* Providers */
import { RecipeResolver } from "./resolvers/recipe.resolver";
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { ModalComponent } from "./directives/modal.directive";
import { ActionPlanComponent } from "./components/action-plan/action-plan.component";
import { AboutComponent } from "./components/about/about.component";

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: "horizontal",
  slidesPerView: 3,
  slidesOffsetAfter: 200,
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HealthComponent,
    HomeComponent,
    NotFoundComponent,
    AdvicesComponent,
    HeaderComponent,
    FooterComponent,
    NavbarComponent,
    AdviceComponent,
    LoginComponent,
    ModalComponent,
    ActionPlanComponent,
    RecipesComponent,
    RecipeComponent,
    PrintableRecipeComponent,
    RecipePreviewComponent,
    // RecipeFormComponent,
    AboutComponent,
    QuizComponent,
    QuizResultComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    SwiperModule,
    NgxPaginationModule,
    CookieLawModule,
    MatTabsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MatomoModule,
  ],
  providers: [
    RecipeResolver,
    { provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
