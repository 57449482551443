import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

/* Components */
import { HealthComponent } from "./components/health/health.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { HomeComponent } from "./components/home/home.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { AdvicesComponent } from "./components/advices/advices.component";
import { RecipesComponent } from "./components/recipes/recipes.component";
import { RecipeComponent } from "./components/recipes/recipe/recipe.component";
import { RecipeFormComponent } from "./components/recipes/recipe-form/recipe-form.component";
import { ActionPlanComponent } from "./components/action-plan/action-plan.component";
import { AboutComponent } from "./components/about/about.component";
import { AdviceComponent } from "./components/advices/advice/advice.component";
import { QuizComponent } from "./components/quiz/quiz.component";

/* Providers */
import { AdviceResolver } from "./resolvers/advice.resolver";
import { RecipeResolver } from "./resolvers/recipe.resolver";
import { AuthGuard } from "./guards/auth.guard";
import { PrintableRecipeComponent } from "./components/recipes/print-recipe/print-recipe.component";

const routes: Routes = [
  {
    path: "home",
    children: [
      { path: "", component: HeaderComponent, outlet: "header" },
      { path: "", component: ActionPlanComponent, outlet: "sidenav" },
      { path: "", component: HomeComponent, data: { title: "HOME" } },
      { path: "", component: FooterComponent, outlet: "footer" },
    ],
  },
  { path: "scouting", pathMatch: "full", redirectTo: "scouting/diet" },
  {
    path: "scouting",
    children: [
      { path: "", component: HeaderComponent, outlet: "header" },
      { path: "", component: ActionPlanComponent, outlet: "sidenav" },
      { path: "", component: FooterComponent, outlet: "footer" },
    ],
  },
  {
    path: "recipes",
    children: [
      { path: "", component: HeaderComponent, outlet: "header" },
      { path: "", component: ActionPlanComponent, outlet: "sidenav" },
      { path: "", component: FooterComponent, outlet: "footer" },
      {
        path: "",
        component: RecipesComponent,
        data: { title: "RECIPES.ROOT" },
      },
      //     {
      //       path: "new",
      //       canActivate: [AuthGuard],
      //       component: RecipeFormComponent,
      //       data: { title: "RECIPES.NEW" },
      //     },
      {
        path: ":id",
        resolve: { recipe: RecipeResolver },
        children: [
          {
            path: "",
            component: RecipeComponent,
            data: { title: "RECIPES.RECIPE" },
          },
          //         {
          //           path: "edit",
          //           canActivate: [AuthGuard],
          //           component: RecipeFormComponent,
          //           data: { title: "RECIPES.RECIPE_EDIT" },
          //         },
        ],
      },
    ],
  },
  {
    path: "advices",
    children: [
      { path: "", component: HeaderComponent, outlet: "header" },
      { path: "", component: ActionPlanComponent, outlet: "sidenav" },
      {
        path: "",
        component: AdvicesComponent,
        data: { title: "ADVICES.ROOT" },
      },
      { path: "", component: FooterComponent, outlet: "footer" },
      {
        path: ":id",
        resolve: { advice: AdviceResolver },
        children: [
          {
            path: "",
            component: AdviceComponent,
            data: { title: "ADVICES.ADVICE" },
          },
        ],
      },
    ],
  },
  {
    path: "print",
    children: [
      {
        path: "recipe",
        children: [
          {
            path: ":id",
            resolve: { recipe: RecipeResolver },
            children: [
              {
                path: "",
                component: PrintableRecipeComponent,
                data: { title: "PRINT_RECIPE" },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "about",
    children: [
      { path: "", component: HeaderComponent, outlet: "header" },
      { path: "", component: ActionPlanComponent, outlet: "sidenav" },
      { path: "", component: AboutComponent, data: { title: "ABOUT" } },
      { path: "", component: FooterComponent, outlet: "footer" },
    ],
  },
  { path: "health", component: HealthComponent, data: { title: "HEALTH" } },
  { path: "", pathMatch: "full", redirectTo: "/home" },
  {
    path: "**",
    children: [
      { path: "", component: NotFoundComponent, data: { title: "NOT_FOUND" } },
      { path: "", component: HeaderComponent, outlet: "header" },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
