import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

import { Recipe } from '../class/recipe.class';
import { RecipesService } from '../services/recipes.service';

@Injectable({
    providedIn: 'root',
})
export class RecipeResolver implements Resolve<Recipe> {
    constructor(private recipesService: RecipesService, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Recipe> | Observable<never> {
        let id = route.paramMap.get('id');

        return this.recipesService.findOne(+id).pipe(
            take(1),
            mergeMap(recipe => {
                if (recipe) {
                    return of(recipe);
                } else {
                    this.router.navigate(['/404'], { skipLocationChange: true });
                    return EMPTY;
                }
            })
        );
    }
}