import { version } from '../../package.json';

export const environment = {
  version: version,
  apiUrl: "http://localhost:3000/",
  production: false,
  tracker: {
    matomo: {
      baseUrl: 'https://statweb.grandlyon.com/',
      clientId: 74
    }
  }
};