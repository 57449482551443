import { Injectable } from "@angular/core";
import {
  Router,
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { Observable, of, EMPTY } from "rxjs";
import { mergeMap, take } from "rxjs/operators";

import { Advice } from "../class/advice.class";
import { AdvicesService } from "../services/advices.service";

@Injectable({
  providedIn: "root",
})
export class AdviceResolver implements Resolve<Advice> {
  constructor(private advicesService: AdvicesService, private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Advice> | Observable<never> {
    let id = route.paramMap.get("id");

    return this.advicesService.findOne(+id).pipe(
      take(1),
      mergeMap((advice) => {
        if (advice) {
          return of(advice);
        } else {
          this.router.navigate(["/404"], { skipLocationChange: true });
          return EMPTY;
        }
      })
    );
  }
}
