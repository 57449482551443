import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }

  /**
   * Login
   * @param credentials 
   */
  public login(credentials: { username: string, password: string }) {
    return this.http.post('/api/login', credentials);
  }
}
