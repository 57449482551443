import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AdviceCategory } from "../class/adviceCategory.class";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AdviceCategoriesService {
  constructor(private http: HttpClient) {}

  public findAll(): Observable<AdviceCategory[]> {
    return this.http.get<AdviceCategory[]>(`/api/adviceCategories`);
  }

  public findOne(id: number): Observable<AdviceCategory> {
    return this.http.get<AdviceCategory>(`/api/adviceCategories/${id}`);
  }
}
