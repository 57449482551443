import { Component, Input } from "@angular/core";
import { Recipe } from "src/app/class/recipe.class";

@Component({
  selector: "app-recipe-preview",
  templateUrl: "./recipe-preview.component.html",
  styleUrls: ["./recipe-preview.component.scss"],
})
export class RecipePreviewComponent {
  @Input() public recipe: Recipe;
  @Input() public isPrevious: boolean;
  @Input() public isNext: boolean;
}
