import { Component, HostListener } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { MatomoTracker } from "ngx-matomo";
import { MatomoInjector } from "ngx-matomo";
import { environment } from "../environments/environment";
import { filter, map, mergeMap } from "rxjs/operators";
import { StorageService } from "./services/storage.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  public lastScrollTop = 0;
  public headerClass = "top";

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translate: TranslateService,
    private matomoInjector: MatomoInjector,
    private matomoTracker: MatomoTracker,
    private storageService: StorageService
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang("fr");
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use("fr");

    // Initialize Matomo tracker
    if (environment.tracker.matomo) {
      this.matomoInjector.init(
        environment.tracker.matomo.baseUrl,
        environment.tracker.matomo.clientId
      );
    }
  }

  ngOnInit() {
    // Set page title from routes
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        this.translate
          .get(event["title"] ? "TITLES." + event["title"] : "TITLES.DEFAULT")
          .subscribe((res) => {
            this.titleService.setTitle(res);
            this.matomoTracker.trackPageView(res);
          });
      });

    this.storageService.init();
  }

  public onActivate() {
    window.scroll(0, 0);
  }

  private isDesktop() {
    return window.innerWidth > 992;
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    let currScrollTop = window.scrollY;
    let isScrollingDown = currScrollTop > this.lastScrollTop;
    this.lastScrollTop = currScrollTop;

    /* Handle menu display on scroll */
    if (currScrollTop === 0) {
      this.headerClass = "";
    } else if (isScrollingDown && !this.isDesktop()) {
      this.headerClass = "hidden";
    } else {
      this.headerClass = "middle";
    }
  }
}
