import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, ElementRef, OnInit } from "@angular/core";
import { AdviceCategory } from "src/app/class/adviceCategory.class";
import { AdviceCategoriesService } from "src/app/services/adviceCategories.service";

@Component({
  selector: "app-advices",
  templateUrl: "./advices.component.html",
  styleUrls: ["./advices.component.scss"],
})
export class AdvicesComponent implements OnInit {
  public categories: AdviceCategory[];

  constructor(
    private adviceCategoriesService: AdviceCategoriesService,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.adviceCategoriesService.findAll().subscribe((categories) => {
      this.categories = categories;
    });
  }

  goToCategory(id: number) {
    const offset = window.innerWidth > 992 ? 100 : 0;
    const element = this.el.nativeElement.querySelector("#category-" + id);
    window.scroll({
      top: element.offsetTop - offset,
      left: 0,
      behavior: "smooth",
    });
  }
}
