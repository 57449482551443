export class Recipe {
    id: number;
    name: string;
    servings: string;
    type: number = 1;
    spring: boolean;
    summer: boolean;
    autumn: boolean;
    winter: boolean;
    prepTime: number;
    cookTime: number;
    ingredients: string;
    equipment: string;
    directions: string;
    note: string;
    
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}