import { Component, ElementRef, OnInit } from "@angular/core";
import { Router, NavigationEnd, Event } from "@angular/router";
import { filter } from "rxjs/operators";
import { AppComponent } from "src/app/app.component";
import { StorageService } from "src/app/services/storage.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  public currentUrl = "/home";
  public nbActions = 0;

  constructor(
    private storageService: StorageService,
    private router: Router,
    private el: ElementRef,
    private appComponent: AppComponent
  ) {}

  ngOnInit() {
    // Listen route changes to send tag trace
    this.currentUrl = this.router.url;
    this.router.events
      .pipe(filter((event: Event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.currentUrl = event.url;
      });

    this.getActionsCount();
    this.storageService.onActionPlanUpdate.subscribe(() => {
      this.appComponent.headerClass = "middle";
      this.triggerCountBadgeAnimation();
      this.getActionsCount();
    });
  }

  public setOverflow(status: string) {
    document.body.style.overflow = status;
  }

  private getActionsCount() {
    this.nbActions = this.storageService.getActionsCount();
  }

  public showBasket() {
    this.storageService.setShowBasket(true);
  }

  private triggerCountBadgeAnimation() {
    const countBadge = this.el.nativeElement.querySelector(".count-badge");
    countBadge.classList.add("beat");
    setTimeout(() => {
      countBadge.classList.remove("beat");
    }, 700);
  }
}
